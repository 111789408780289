<template>
  <div v-if="loaderStyle === 'fullscreen'">
    <div class="loading bg-grayMouse">
      <div class="loadingScreen bg-grayMouse">
        <div>
          <ProgressSpinner
            strokeWidth="2"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        </div>
        <h6>... wird geladen ...</h6>
				<h6 v-if="loaderTitle !== null && loaderTitle !== undefined">{{ loaderTitle }}</h6>
      </div>
    </div>
  </div>
  <div v-if="loaderStyle === 'box'">
    <div class="box loadingScreen bg-grayMouse">
      <div>
        <ProgressSpinner
          strokeWidth="2"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </div>
      <h6>... bitte warten ...</h6>
			<h6 v-if="loaderTitle !== null && loaderTitle !== undefined">{{ loaderTitle }}</h6>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  addEventListener,
  removeEventListener,
} from "@/services/eventListener";

export default defineComponent({
  name: "Loader",
  mixins: [addEventListener, removeEventListener],
  components: {},
  data() {
    return {};
  },
  props: {
    style: {
      type: String,
      required: true,
    },
		title: {
      type: String,
      required: false,
    },
  },
  computed: {
    loaderStyle: function () {
      return this.style;
    },
		loaderTitle: function () {
			return this.title;
		}
  },
});
</script>

<style scoped>
.box {
  background-color: var(--surface-e);
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 4px;
  box-shadow: 10px 10px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
	opacity: 0.9;
}
.loadingScreen {
  line-height: 40px;
	text-align: center;
  padding: 2em;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  z-index: 1051;
	opacity: 0.9;
}
</style>