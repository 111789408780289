<template>
	<div class="custom-skeleton p-4">
		<div class="flex mb-3">
			<div>
				<Skeleton shape="circle" size="4rem" class="mr-2"></Skeleton>
			</div>
			<div>
				<Skeleton width="10rem" class="mb-2"></Skeleton>
				<Skeleton width="5rem" class="mb-2"></Skeleton>
				<Skeleton height=".5rem"></Skeleton>
			</div>
		</div>
		<Skeleton width="100%" height="100px"></Skeleton>
		<div class="flex justify-content-center mt-3">
			<Skeleton width="4rem" height="2rem"></Skeleton>
			<Skeleton width="4rem" height="2rem"></Skeleton>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "loaderSkeleton",
  setup() {},
  props: {
  },
  watch: {
  },
  computed: {
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
  },
  methods: {
  },
});
</script>

<style lang="scss" scoped>
</style>